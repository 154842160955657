.equipe.contained-small{
  max-width: 600px;
  text-align: center;
  
  h3{
    color: $green;;
  }
  ul{
    list-style: none;
    padding: 0;
  }
  li{
    margin-bottom: 60px;
  }
}