p.legend{
  font-weight: bold;
}
span.subtitle{
  display: block;
  text-align: center;
  text-transform: uppercase;
  color: $green;
  font-size: 1.1em;
  margin-bottom: 40px;
}

#bodymain #frame section{
  margin-bottom: 60px;

  h3{
    margin: 40px 30px;
  }

  .btn-wrap{
    text-align: center;
    margin: 60px auto;
  }
  .btn{
    display: inline-block;
  }
}

.alinea{
  color: #aaa;
}

.dimensions-mesurees .shape-svg{
  fill: $green;
  text-align: center;
  width: 120px;
  height: 120px;
  margin: 0 auto;
  display: inherit;
}