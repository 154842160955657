.admin-wrap{
  background: $darkerGrey;
  color: #fff;
  overflow: hidden;

  .fieldset-alt-box{
    overflow: hidden;
    margin: 40px auto;

    h3{
      color: #666;
      float: left;
      max-width: 50%;
      text-align: left;
    }
    div{
      float: right;
      max-width: 45%;
    }
  }
  a{
    color: #fff;
  }
  .pagination, .ilvousreste{
    margin: 0;
    padding: 20px 0;
  }
}

.dark-form{
  form{
    text-align: center;
    margin-top: 80px;
  }
  .row{
    display: inline-block;
    margin: 0 10px;
    width: 250px;

    label{
      display: none;
    }

    select{
      padding: 5px;
    }
  }
  .text-input{
    background: #3E403F;
    padding: 10px;
    border: none;
    color: #fff;
    font-size: 1em;
    width: 100%;
  }
  .btn-third{
    width: 33%;
    max-width: 200px;
    margin: 50px auto 40px;
    display: block;
  }
  .login-form{
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;

    .row{
      width: 45%;
      label{
        display: none;
      }
    }
    .row1{
      margin-right: 5%;
    }
    .text-input{
      width: 100%;
      box-sizing: border-box;
    }
    .rememberfield{
      margin: 20px auto;
      label, .field{
        display: inline-block;
      }
    }
    .links{
      text-align: center;
      margin-top: 20px;
      font-size: .8em;
    }
    .links .field{
      display: inline-block;
      margin: 0 10px;
    }
    a{
      color: #fff;
    }
  }
  table.colore{
    background: #fff;
    border-spacing: 0;
    margin: 0 auto;

    thead{
      background: $green;
    }
    th{
      padding: 10px 5px;
    }
    td{
      padding: 10px;
      border-bottom: 1px solid #eee;
    }
    a{
      color: #000;
    }
  }
  .table-copy{
    float: left;
    width: 50%;
  }
}

.dark-form #evaluations.colore td{
  color: #000;
}

.mot-de-passe-perdu{
  .dark-form form{
    margin-top: 40px;
  }
  .row {
    width: 50%;
  }
  .btn-third{
    margin: 20px auto;
  }
}

.shape-logout{
  fill: $green;
  width: 32px;
  height: 32px;
}

.header-top nav.admin-menu {
  float: none;
  margin: 0 auto;
  width: 600px;
  display: inline-block;
  ul {
    margin: 0;
    padding: 0;
  }
  a.adminNavItem {
    color: #666;
    display: block;
    text-decoration: none;

    .shape-svg{
      fill: #666;
      width:32px;
      height: 32px;
      display: inline-block;
    }
    span{
      display: inline-block;
    }

    &:hover {
      color: $green;
      border-bottom: 2px solid $green;

      .shape-svg{
        fill: $green;
      }
    }
  }
}

#fiche_candidat{
    width: 400px;
    text-align: left;
    margin-right: 100px;
}

.mon-espace-personnel,
.creer-un-test,
.detail-du-test,
.modifier-un-test,
.get-token,
.mot-de-passe-perdu,
.faire-passer-le-test-au-candidat,
.se-connecter-a-son-compte-entreprise,
.rapport-entreprise,
.rapport-candidat{
  #header{
    background-image: none !important;
    height: 82px;
    min-height: 0 !important;
  }
  .header-top{
    background: #1d1f1f;
    padding: 20px 50px;
  }
  .header-top nav.admin-menu{
    float: none;
    margin: 0 auto;
    width: 550px;
    ul{
      margin: 0;
    }
  }
  .header-encart{
    display: none;
  }
}
.clear{
  clear: both;
}

.candidate-search-wrap{
  overflow: hidden;
  margin-bottom: 10px;

  form{
    float: right;
  }
  input.text{
    padding: 11px;
    width: 300px;
    border: none;
  }
  .btn{
    margin: 0;
    display: inline-block;
    width: auto;
  }
}