#bodytest {
  color: #fff;

  #container{
    margin: 20px;
    background: $green;
    padding: 50px;
  }

  #header .subtitle{
    font-size: 2em;
    margin-bottom: 0;
  }
}

#bodytest #centre {
  width: 964px;
  min-height: 400px;
  margin: 0 auto;
}

#centre .round-top,
#centre .round-bottom {
  height: 6px;
  line-height: 0;
  overflow: hidden;
}
#centre .round {
  padding: 15px 25px;
  min-height: 100px;
  margin: 0 auto;
}

#modalboxTest {
  position: relative;
}
kbd{
  font-size: 1.8em;
}

#modalboxTest #testTimer {
  text-align: center;
}

#modalboxTest #underframe {
  width: 99%;
}

#modalboxTest #left,
#modalboxTest #right {
  width: 225px;
}

#modalboxTest #word,
#modalboxTest #word-infos {
  clear: both;
  font-size: 35px;
  position: absolute;
  text-align: center;
  left: 0;
  top: 200px;
  width: 100%;
}

#modalboxTest #word-infos {
  font-size: 14px;
}

#modalboxTest #imageTest {
  position: absolute;
  top: 80px;
  left: 0;
  width: 96%;
  text-align: center;
}

#modalboxTest #img_wrong {
  position: absolute;
  top: 120px;
  left: 15px;
  width: 96%;
  text-align: center;
}

#modalboxTest #loading {
  position: absolute;
  top: 80px;
  left: 0;
  width: 96%;
  text-align: center;
}

#modalboxTest #listWordsLeft {
  clear: left;
  list-style: square;
  padding-top: 15px;
  margin-left: 15px;
  font-size: 16px;
  text-align: left;
}

#modalboxTest #listWordsRight {
  clear: right;
  list-style: square;
  padding-top: 15px;
  margin-left: 620px;
  font-size: 16px;
  text-align: left;
  margin-top: -50px;
}

#modalboxTest h3.legendleft{
  font-size: 1.5em;
  margin-bottom: 10px;
}

#modalboxTest .footer {
  position: absolute;
  left: 0;
  bottom: 20px;
  width: 75%;
  margin-left: 12.5%;
}
#modalboxTest .footer .button {
  position: relative;
  top: 5px;
  margin: 0 65px;
  display: block;
}

.mots {
  font-size: 30px;
  text-align: center;
  width: 96%;
}

.form p {}
#centre .form fieldset {margin-bottom:10px;}
#centre .form legend.active {font-size:13px;}
#centre .form ul {margin: 0;}
#centre .form  li {padding:3px 0;}

.error_list{
  list-style: none;
  color: #0f7595;
  padding: 0;
}

.test_content,
.stage_content,
.cachediv,
.hidden {
  display: none;
}

.test_content{
  text-align: center;
}
#bodytest .contained h3, #bodytest #frame .subtitle{
  text-align: left;
}
.ui-widget-content a{
  color: $green;
}
.acenter{
  margin: 40px 0;
}

img#img_left.fright{
  margin-top: 1em;
  margin-left: 1em;
}
.fleft {
  float: left;
}
.fright {
  float: right;
}
img#img_right.fleft{
  margin-top: 1em;
  margin-right: 1em;
}
.fleftrightwrap{
  overflow: hidden;
}
.fleftwrap{
  float:left;
  width: 48%;
}
.frightwrap{
  float: right;
  width: 48%;
}
#modalboxTest .ui-corner-all{
  border: 1px solid #000;
  border-radius: 5px;
  padding: 10px 10px;
  text-align: center;
}
#keyboard.footer .button.ui-corner-all{
  background: $green;
  color: #fff;
}
.questionnaire fieldset{
  margin-bottom: 40px;
}
form.verifnomform{
  margin: 40px auto;
  .row{
    margin-bottom: 20px;
  }
}
.verifnomform .text-input{
  background: #fff;
  color: #3E403F;
}

.ui-dialog-titlebar.ui-widget-header.ui-corner-all.ui-helper-clearfix{
  display: none;
}
.ui-dialog.ui-widget.ui-widget-content.ui-corner-all {
  border: 20px solid $green;
}
.spaceToContinue{
  background: rgba(0,0,0,0.2);
  text-align: center;
  padding: 20px;
  margin-top: 40px;
}
#modalboxTest .spaceToContinue{
  padding: 10px;
  margin-top: 20px;
  .acenter{
    margin: 10px;
  }
}
.pressF, .pressJ{
  font-size: 1.5em;
  color: $green;
  clear: both;
}
.legendleft{
  text-align: left;
  color: $green;
}
.exend{
  margin: 60px auto;
  p{
    margin-bottom: 30px;
  }
  a.btn-primary{
    color: #fff;
    &:hover{
      color: $green;
    }
  }
}
.center{
  text-align: center;
}


.questionnaire fieldset{
  margin-top: 50px;
  border: none;
  padding: 0;

  legend{
    font-size: 1.5em;
    margin-bottom: 20px;
  }
  .radio_list{
    margin: 0;
    padding: 0;
    list-style: none;
    li{
      margin-bottom: 5px;
    }
  }
  input[type="radio"]{

  }
}