.contained{
  max-width: $contained;
  margin-left: auto;
  margin-right: auto;
}
.contained-small{
  max-width: $containedSmall;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: $contained) {
  .contained{
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (max-width: $containedSmall) {
  .contained-small{
    padding-left: 30px;
    padding-right: 30px;
  }
}