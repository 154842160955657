$green : #71ab55;
$blue: #1aa2bf;
$orange: #d96131;
$darkGrey: rgba(0,0,0,0.6);
$lightGrey: #ebebeb;
$darkerGrey: #262729;

$contained: 1200px;
$containedSmall: 1080px;

$imgPath: '/assets/raw/images/';

$font1: 'Maven Pro', sans-serif;