#bodyhome #header, #bodymain #header {
  position: relative;
  width: 100%;
  background-size: cover;
  background-position: center center;
  min-height: 400px;

  &::before {
    content: '';
    background: $darkGrey;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

body.les-mesures-implicites #header {
  background-position: center -60px;
}

#bodyhome #header {
  height: 900px;
}

#bodyhome #header::before {
  display: none;
}

.header-top {
  position: absolute;
  padding: 20px 50px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;

  .contained {
    overflow: hidden;
  }

  .logo {
    float: left;
  }
  nav, .actions {
    float: right;
  }
  nav {
    ul{
      padding: 0;
    }
    li {
      display: inline-block;
      margin-right: 20px;
    }
    a {
      color: #fff;
      text-decoration: none;
      text-transform: uppercase;

      &:hover {
        color: #ddd;
        text-decoration: underline;
      }
    }
  }
  .menu-toggle{
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    display: none;
    margin-right: 20px;
  }
  .actions .btn {
    display: inline-block;
  }
}

#bodyhome .header-top {
  background: rgba(0, 0, 0, 0.1);
}

.header-encart {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: #999999;
  text-align: center;

  .contained-small {
    padding: 40px;
  }

  h1 {
    color: $green;
    font-size: 2.5em;
    text-transform: uppercase;
    font-weight: 400;

    &::after {
      content: '';
      width: 50px;
      border: 1px solid #fff;
      display: block;
      margin: 20px auto;
    }
  }
  .subtitle {
    font-size: 1.5em;
    max-width: 650px;
    margin: 0 auto;
  }
  ul {
    list-style: none;
    margin-top: 40px;
    margin-bottom: 0;
    padding: 0;
  }
  li {
    display: inline-block;
    width: 210px;
    text-align: left;
    vertical-align: top;
    margin: 0 50px;
    strong {
      color: $green;
      text-transform: uppercase;
      font-size: 1.2em;
      font-weight: 800;
    }
  }
}

#bodymain .header-encart .subtitle,
#bodytest .header-encart .subtitle {
  color: #fff;
}
@media (max-width: 1200px){
  .header-top .contained{
    padding: 0;
  }
  .header-top nav li{
    margin-right: 15px;
    font-size: .9em;
  }
}
@media (max-width: 1200px){
  .header-top .menu-toggle{
    display: inline-block;
  }
  .header-top nav{
    width: 100%;
    text-align: center;
    margin-top: 20px;
    display: none;
    position: relative;
    z-index: 4;
    background: rgba(0,0,0,0.7);
    padding: 20px;
    box-sizing: border-box;
    ul{
      margin: 0;
    }
  }
}
@media (max-width: 800px) {
  .header-top {
    padding: 20px;
    .actions .login-btn{
      display: none;
    }
  }

}
@media (max-width: 600px) {
  #bodyhome #header{
    height: auto;
  }
  .header-top, .header-encart{
    position: relative !important;
    background: rgba(0, 0, 0, 0.8) !important;
  }
  .header-top nav li{
    display: block;
    margin: 0;
    a{
      display: block;
      padding: 10px;
    }
  }
}
@media (max-width: 500px) {
  section.dimensions .contained-small ul {
    margin: 50px auto;
  }
  .header-top .menu-toggle{
    padding: 15px 0;
    margin-right: 0;
  }
  .header-top .actions .ctc-btn{
    display: none;
  }
}