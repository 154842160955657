section.clients{
  background: $orange;
  color: #fff;

  ul{
    list-style: none;
    text-align: center;
    margin-top: 40px;
  }
  li{
    display: inline-block;
    margin: 0 25px;
  }
}