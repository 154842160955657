#footer{
  text-align: center;
  text-transform: uppercase;

  .copyright{
    color: $green;
    display: inline-block;
  }

  nav, li{
    display: inline-block;
  }
  ul{
    padding: 0;
  }
  li{
   margin-left: 20px;
  }
  a{
    color: #000;
    text-decoration: none;
    &:hover{
      text-decoration: underline;
    }
  }
}