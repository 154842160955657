.contactez-nous{
  legend{
    display: none;
  }
  fieldset{
    border: none;
    padding: 0;
  }
  label{
    display: none;
  }
  .text-input, textarea{
    background: #EEE;
    padding: 10px;
    border: none;
    color: #3E403F;
    font-size: 1em;
    width: 100%;
    box-sizing: border-box;
  }
  .fs1{
    margin-bottom: 20px;
    .row{
      display: inline-block;
      margin: 0;
      width: 32%;
    }
    .middlerow{
      margin: 0 1.5%;
    }
  }
  .subjectRow{
    margin-bottom: 15px;
  }
  .btn-third{
    width: 33%;
    max-width: 200px;
    margin: 20px auto 0;
    display: block;
    padding: 10px 0;
    text-transform: uppercase;
    border-style: solid;
    font-size: 1em;
  }
}
@media (max-width: 1100px) {
  .contactez-nous .fs1 .middlerow{
    margin: 0 1.3%;
  }
}

@media (max-width: 850px) {
  .contactez-nous .fs1 .middlerow{
    margin: 0 1.1%;
  }
}

@media (max-width: 650px) {
  .contactez-nous .fs1 .middlerow{
    margin: 0 .5%;
  }
}
@media (max-width: 450px) {
  .contactez-nous .fs1 .row{
    display: block;
    width: auto;
    margin-top: 10px;
  }
}