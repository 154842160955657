@import "home/dimensions";
@import "home/about";
@import "home/viahumanis";
@import "home/clients";

#bodymain > font{
  display: none;
}

#bodyhome{
  .header-encart{
    background: $darkGrey;
  }
  h1{
    color: #fff;
    font-size: 2.8em;
    
    &::after{
      display: none;
    }
  }
  h1 span{
    color: $green;
  }
}

.home section{
  padding: 100px;

  h2{
    max-width: 600px;
    margin: 0 auto;
  }
}

@media (max-width: 700px) {
  .home section{
    padding: 50px;
    .contained-small{
      padding: 0;
    }
  }
}
@media (max-width: 600px) {
  #bodyhome h1{
    font-size: 1.5em;
  }
  .header-encart li{
    margin: 0 10px;
  }
}
@media (max-width: 350px) {
  .home section{
    padding: 30px;
  }
}