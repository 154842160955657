section.viaHumanis{
  .contained-small {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div{
      max-width: 500px;
    }
  }

}

@media (max-width: 700px) {
  section.viaHumanis .contained-small {
    flex-direction: column;
  }
  section.viaHumanis .contained-small > div{
    margin-bottom: 20px;
  }
}