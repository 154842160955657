#rapport-test{
  .logo-square{
    margin: 50px auto;
    width: 300px;
    display: block;
  }
  .parcque{
    overflow: hidden;
    .pleft{
      float: left;
      width: 49%;
    }
    .pright{
      float: right;
      width: 49%;
    }
  }
  h2{
    text-align: left;
    display: block;
    border-bottom: 2px solid #000;
  }
  h3{
    text-align: left;
    color: $green;
    font-weight: bold;
    font-size: 1.9em;
    margin: 60px 0 20px;
  }
  h4{
    text-align: left;
    color: $green;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.4em;
    margin-bottom: 0;
  }
  h5{
    font-size: 1em;
    text-decoration: underline;
    margin-bottom: 0;
  }
  hr{
    border-top: 1px solid #ddd;
    border-bottom: none;
    margin-top: 80px;
    display: block;
    margin-bottom: 0;
  }
  .page-break{
    page-break-before: always;
  }
  .grey{
    background: #D9DAD9;
    padding: 20px;
    page-break-inside: avoid;

    p:first-child{
      margin-top: 0;
    }
    p:last-child{
      margin-bottom: 0;
    }
  }
  .protected-wrap{
    page-break-inside: avoid;
  }
  .gauss, .barre {
    position: relative;
    page-break-inside: avoid;

    img {
      width: 100%;
      height: auto;
    }
    .cursor{
      position: absolute;
      top: 0;
      bottom: 0;
      width: 3px;
      background: #000;
    }
  }
  .gauss{
    padding-bottom: 20px;
    width: 60%;
    margin: 100px auto 0;
    max-width: 800px;
  }
  .barre{
    padding: 10px 0;
    margin-right: 3px;
  }

  .barre-wrap{
    overflow: hidden;
    margin-bottom: 20px;

    > label{
      float: left;
      width: 30%;
      font-weight: bold;
      margin-top: 30px;
      text-align: center;
    }
    .barre, .graph-legend{
      float: right;
      width: 66%;
    }
  }
  .graph-legend{
    text-align: center;
    margin: 40px 0 20px;
  }
  .graph-legend span{
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.1em;
  }
  .graph-legend span.label1{
    float: left;
  }
  .graph-legend span.label3{
    float: right;
  }
  .gauss-legend{
    overflow: hidden;
    margin: 0 auto 70px;
    max-width: 75%;

    span{
      font-weight:bold;
    }
    .sp1{
      float: left;
    }
    .sp2{
      float: right;
    }
  }
  span.help, p.help{
    clear: both;
    display: inline-block;
    width: 100%;
    font-size: .8em;
    color: grey;
    font-style: italic;
    margin-top: 10px;
    text-align: center;
  }
  .protected-wrap p.help{
    text-align: left;
    margin-top: 0;
    margin-bottom: 20px;
  }
  .synthese-conclusion{
    margin-top: 100px;
  }
  ul.profil{
    font-weight: bold;
    text-align: right;
    list-style: none;
  }
  .keyword-line{
    margin-bottom: 20px;
    overflow: hidden;

    label{
      float: left;
      width: 75px;
    }
  }
  .keyword-list{
    float: left;
    margin: 0;
    padding: 0;
    width: calc(100% - 100px);

    li{
      display: inline-block;
      background-color: $green;
      color: #fff;
      padding: 4px 5px;
      border-radius: 5px;
      margin-left: 5px;
      margin-bottom: 5px;
    }
  }
  .sociodemo .questions{
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .endbtn{
    margin: 50px 0;
    overflow: hidden;
    text-align: center;
    a{
      display: inline-block;
      margin: 0 10px;
    }
  }
}
.pdfmode{
  #header, .endbtn, #footer{
    display: none;
  }
  #rapport-test{
    font-size: .9em;
  }
  #rapport-test .gauss-legend span{
    font-size: .7em;
  }
}
