section.about{
  background-color: $lightGrey;

  h2 span{
    color: $green;
  }

  .desc{
    margin-top: 20px;
    display: flex;
    .p1{
      margin-right: 20px;
      flex: 1 1 50%;
    }
    .p2{
      margin-left: 20px;
      flex: 1 1 50%;
    }
  }

  .encart{
    padding: 20px;
    background-color: $blue;
    color: #fff;
    font-weight: bold;
    margin-top: 40px;

    ul{
      list-style: none;
      margin: 0;
      padding: 0;
    }
    li{
      height: 40px;
      text-align: center;
    }
  }

  .btn-third{
    width: 200px;
    display: block;
    text-align: center;
    margin: 50px auto 0;
  }
}

@media (max-width: 700px) {
  section.about .desc {
    display: block;
  }
  section.about .desc .p1, section.about .desc .p2{
    margin: 0 0 10px 0;
  }

}