.btn{
  color: #fff;
  background-color: $darkGrey;
  text-decoration: none;
  text-transform: uppercase;
  padding: 10px 15px;
  border: 2px solid $darkGrey;
  display: inline-block;
}
.btn-primary{
  background-color: $green;
  border-color: $green;
  &:hover{
    border-color: $green;
    color: $green;
    background: none;
  }
}
#bodyhome .btn-primary:hover{
    border-color: #fff;
    color: #fff;
}
.btn-secondary{
  border-color: #fff;
  background: none;
  &:hover{
    background: #fff;
    color: #000;
  }
}
.btn-third{
  border-color: $green;
  color: $green;
  background: none;
  &:hover{
    background-color: $green;
    border-color: $green;
    color: #fff;
    cursor: pointer;
  }
}