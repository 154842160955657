h2{
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
  font-size: 2.5em;
}
h3{
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
  font-size: 1.8em;
}