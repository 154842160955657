section.dimensions{
  background-image: url('#{$imgPath}home/hero-3.jpg');
  background-size: cover;
  text-align: center;
  position: relative;
  margin-top: -20px;

  &::before{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $blue;
    opacity: 0.8;
    z-index: 0;
  }

  .contained-small{
    position: relative;
    z-index: 1;
  }

  h2{
    color: #fff;
    span {
      color: #000;
    }
  }

  ul{
    color: #fff;
    list-style: none;
    margin: 100px auto;
    padding: 0;
  }
  li{
    display: inline-block;
    vertical-align: top;
    width: 150px;
    margin: 0 20px;
    text-transform: uppercase;
    text-align: center;

    .shape-svg{
      width: 100%;
      margin-bottom: 10px;
      fill: #fff;
    }
  }
  .btn{
    margin: 0 20px 10px;
  }
}